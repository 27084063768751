<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Crear máquina
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="createMachine">
        <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="8">
              <validation-provider
                  #default="{ errors }"
                  :name="'Nombre'"
                  rules="required"
              >
                <b-form-group
                    :label="'Nombre'"
                    label-for="machine-name"
                >
                  <b-form-input
                      v-model="name"
                      name="name"
                      :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="8">
              <b-form-group
                  :label="'Descripción'"
                  label-for="department-description"
              >
                <quill-editor
                    v-model="description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Crear' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      description: '',
      required,
      email,
    }
  },
  methods: {
    ...mapActions({
      create: 'machines/create',
    }),
    handleSubmit() {
      this.$refs.createMachine.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ machine: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.name)
      if (this.description) {
        data.append('description', this.description)
      }

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
